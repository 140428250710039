import React, { useState } from 'react';
import { Link } from 'react-scroll'
import Logo from '../../images/eRachunkowa_logo_white.svg';
import { changeLocale } from 'gatsby-plugin-intl';
import { BiCheckCircle } from 'react-icons/bi'
import { handlerTracking } from '../../helpers/ga';

const NavMobile = ({ intl }) => {
    const [language] = useState(intl.locale);
    const [showMenu, setShowMenu] = useState(false);
    const body = document.querySelector('body');

    const handlerToggleMenu = () => {
        if (showMenu) {
            setShowMenu(false)
            body.style.overflow = 'auto';
        } else {
            setShowMenu(true)
            body.style.overflow = 'hidden';
            handlerTracking('Nawigacja Mobilna', 'Kliknięcie', 'Pokaż menu')
        }
    };

    const handlerChangeLanguage = (lang) => {
        handlerTracking('Nawigacja Mobilna', 'Kliknięcie', `Zmień język na ${lang}`)
        body.style.overflow = 'auto';
        changeLocale(lang)
    };

    const handlerHideMenu = (type) => {
        handlerTracking('Nawigacja Mobilna', 'Kliknięcie', `Przejdź do ${type}`)
        body.style.overflow = 'auto';
        setShowMenu(false)
    };

    return (
        <div className="navigation-mobile-wrapper">
            <img className="logo" src={Logo} alt="main logo" />
            <button type="button" className="mobile-menu-btn" onClick={handlerToggleMenu}>
                <span className={showMenu ? 'close' : ''} />
            </button>
            <div className={`menu-list-wrapper ${showMenu ? 'show' : ''}`}>
                <ul className="nav-list">
                    <li className="nav-list__item item-1">
                        <Link to="benefit-section" offset={-50} smooth={true} duration={800} type="button"
                              onClick={() => handlerHideMenu('Korzyści')}>
                            {intl.formatMessage({ id: 'navigation.benefit' })}
                        </Link>
                    </li>
                    <li className="nav-list__item item-2">
                        <Link to="offer-section" offset={-50} smooth={true} duration={800} type="button"
                              onClick={() => handlerHideMenu('Oferta')}>
                            {intl.formatMessage({ id: 'navigation.offer' })}
                        </Link>
                    </li>
                    <li className="nav-list__item item-3">
                        <Link to="price-section" offset={-50} smooth={true} duration={800} type="button"
                              onClick={() => handlerHideMenu('Cena')}>
                            {intl.formatMessage({ id: 'navigation.price' })}
                        </Link>
                    </li>
                    <li className="nav-list__item item-4">
                        <Link className="contact-btn" to="contact-section" offset={-50} smooth={true} duration={800}
                              type="button" onClick={() => handlerHideMenu('Contact')}>
                            {intl.formatMessage({ id: 'navigation.contact' })}
                        </Link>
                    </li>
                </ul>
                <ul className="nav-list__item item-5 mobile-select-language">
                    <li className="mobile-select-language--item">
                        <button type="button" className={language === 'pl' ? 'active' : ''}
                                onClick={() => handlerChangeLanguage('pl')}>Polski
                            <BiCheckCircle />
                        </button>
                    </li>
                    <li className="mobile-select-language--item">
                        <button type="button" className={language === 'en' ? 'active' : ''}
                                onClick={() => handlerChangeLanguage('en')}>English
                            <BiCheckCircle />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default NavMobile;
