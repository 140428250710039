import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

// import defaultMetaImage from '../../static/images/meta-image-3destate.jpg'

function Seo ({ description, lang, meta, title }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    const url = typeof window !== 'undefined' ? window.location.href : 'undefined';

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        >
            <meta name="description" content={metaDescription} />
            {/*<meta name="image" content={defaultMetaImage} />*/}

            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/*<meta property="og:image" content={defaultMetaImage} />*/}
            <meta property="fb:app_id" content="101689081475413" />

            <meta charSet="utf-8" />
            <meta name="viewport"
                  content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
    )
}

export default Seo
