import * as React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Seo from '../components/Seo';
import HeaderSection from '../components/HomePage/HeaderSection';
import Layout from '../components/Layout';
import NewSection from '../components/HomePage/NewsSection';
import BenefitSection from '../components/HomePage/BenefitSection';
import OfferSection from '../components/HomePage/OfferSection';
import PriceSection from '../components/HomePage/PriceSection';
import ContactSection from '../components/HomePage/ContactSection';

const IndexPage = () => {
    const intl = useIntl();

    return (
        <>
            <Seo
                lang={intl.locale}
                title={intl.formatMessage({ id: 'headHome.title' })}
                description={intl.formatMessage({ id: 'headHome.description' })}
            />
            <Layout intl={intl}>
                <HeaderSection intl={intl} />
                <NewSection intl={intl} />
                <BenefitSection intl={intl} />
                <OfferSection intl={intl} />
                <PriceSection intl={intl} />
                <ContactSection intl={intl} />
            </Layout>
        </>
    )
}

export default IndexPage
