import * as React from 'react'
import Navigation from './Navigation'
import '../styles/index.scss'
import { useEffect, useState } from 'react';
import Footer from './HomePage/Footer';
import PrivacyPolicyPdf from "../static/dokumenty/polityka-prywatnosci-erachunkowa.pdf";

const Layout = ({ children, intl, funds }) => {
    const [isMobile, setIsMobile] = useState(null);
    const [seenCookieInformation, setSeenCookieInformation] = useState(true);
    const [showCookieSettings, setShowCookieSettings] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState(false);
    const [cookieNecessary, setCookieNecessary] = useState(true);
    const [cookieStatistic, setCookieStatistic] = useState(false);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('seenCookieInformation'))) {
            setSeenCookieInformation(true)
        } else {
            setSeenCookieInformation(false);
        }
    }, [seenCookieInformation])

    useEffect(() => {
        const pref = JSON.parse(localStorage.getItem('cPreferences'));
        const stat = JSON.parse(localStorage.getItem('cStatistic'));

        if (pref) setCookiePreferences(pref)
        if (stat) setCookieStatistic(stat)
    }, [])

    useEffect(() => {
        if (window.innerWidth > 840) {
            setIsMobile(false)
        } else {
            setIsMobile(true)
        }
    }, [])

    useEffect(() => {
        function handleResize () {
            if (window.innerWidth > 840) {
                setIsMobile(false)
            } else {
                setIsMobile(true)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [isMobile])

    const handleShowCookieSettings = (e) => {
        e.preventDefault();

        setShowCookieSettings(!showCookieSettings)
    }

    const handlerConfirmSeenCookie = () => {
        localStorage.setItem('seenCookieInformation', 'true');
        setSeenCookieInformation(true);
    };

    const triggerCookiePreferences = () => {
        localStorage.setItem('cPreferences', `${!cookiePreferences}`);
        setCookiePreferences(!cookiePreferences);
    };

    const triggerCookieNecessary = () => {
        localStorage.setItem('cNecessary', `${!cookieNecessary}`);
        setCookieNecessary(!cookieNecessary);
    };

    const triggerCookieStatistic = () => {
        localStorage.setItem('cStatistic', `${!cookieStatistic}`);
        setCookieStatistic(!cookieStatistic);
    };

    const allCookiesApprove = () => {
        localStorage.setItem('cPreferences', 'true');
        localStorage.setItem('cStatistic', 'true');
        setCookieStatistic(true);
        setCookiePreferences(true);

        setShowCookieSettings(false);
    }

    const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { isMobile }));

    return (
        <>
            <Navigation intl={intl} isMobile={isMobile} />
            <main>{childrenWithProps}</main>
            {!seenCookieInformation && (
                <div className="cookie-wrapper">
                    <div className="cookie-wrapper__text">
                        {intl.formatMessage({ id: 'homePage.cookies.text1' })}
                        <span>{
                            intl.formatMessage({ id: 'homePage.cookies.textSpan' })}
                        </span>
                        {intl.formatMessage({ id: 'homePage.cookies.text2' })}
                        <a
                            className="cookie-settings-button"
                            onClick={handleShowCookieSettings}><b>"{intl.formatMessage({ id: 'homePage.cookies.text3' })}"</b>
                        </a>
                        {intl.formatMessage({ id: 'homePage.cookies.text4' })}
                    </div>
                    <button type="button" aria-label="close button" onClick={handlerConfirmSeenCookie}>
                        <div />
                    </button>
                </div>
            )}
            {showCookieSettings && (
                <div className="cookie-settings">
                    <div className="cookie-settings-backdrop">
                        <div
                            className="cookie-settings-backdrop__close-area"
                            onClick={handleShowCookieSettings}
                        />
                        <div className="cookie-settings-container">
                            <div className="content">
                                <div className="header">
                                    {intl.formatMessage({ id: 'homePage.cookieSettings.title' })}
                                </div>
                                <div className="body">
                                    <div className="necessary">
                                        <div className="d-flex">
                                            <div className="title">
                                                {intl.formatMessage({ id: 'homePage.cookieSettings.titles.necessary' })}
                                            </div>
                                            <div className="switch">
                                                <div
                                                    className={`btn ${cookieNecessary ? 'active' : ''}`}
                                                    onClick={triggerCookieNecessary}
                                                />
                                            </div>
                                        </div>
                                        <div className="text">
                                            {intl.formatMessage({ id: 'homePage.cookieSettings.descriptions.necessary' })}
                                        </div>
                                    </div>
                                    <div className="preferences">
                                        <div className="d-flex">
                                            <div className="title">
                                                {intl.formatMessage({ id: 'homePage.cookieSettings.titles.preferences' })}
                                            </div>
                                            <div
                                                className="switch"
                                            >
                                                <div
                                                    className={`btn ${cookiePreferences ? 'active' : ''}`}
                                                    onClick={triggerCookiePreferences}
                                                />
                                            </div>
                                        </div>
                                        <div className="text">
                                            {intl.formatMessage({ id: 'homePage.cookieSettings.descriptions.preferences' })}
                                        </div>
                                    </div>
                                    <div className="statistic">
                                        <div className="d-flex">
                                            <div className="title">
                                                {intl.formatMessage({ id: 'homePage.cookieSettings.titles.statistic' })}
                                            </div>
                                            <div className="switch">
                                                <div
                                                    className={`btn ${cookieStatistic ? 'active' : ''}`}
                                                    onClick={triggerCookieStatistic}
                                                />
                                            </div>
                                        </div>
                                        <div className="text">
                                            {intl.formatMessage({ id: 'homePage.cookieSettings.descriptions.statistic' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="footer">
                                    <button className="outline-btn" onClick={() => setShowCookieSettings(false)}>
                                        {intl.formatMessage({ id: 'homePage.cookieSettings.approveChosenBtn' })}
                                    </button>
                                    <button className="primary-btn" onClick={allCookiesApprove}>
                                        {intl.formatMessage({ id: 'homePage.cookieSettings.approveAllBtn' })}
                                    </button>
                                </div>
                            </div>
                            <div
                                className="close-button"
                                onClick={handleShowCookieSettings}
                            />
                        </div>
                    </div>
                </div>
            )}
            <Footer intl={intl} />
            <div className="d-flex under-footer" style={{ backgroundColor: '#0B4169', padding: '1em', color: '#FFF' }}>
                <div className="content">
                    © eRachunkowa {new Date().getFullYear()} /
                    <a
                        className="cookie-settings-button"
                        onClick={handleShowCookieSettings}><b>{intl.formatMessage({ id: 'homePage.cookies.text3' })}</b>
                    </a>
                    {' / '}
                    <a
                      className="prviacy-policy"
                      rel={'noopener noreferrer'}
                      target={'_blank'}
                      href={PrivacyPolicyPdf}
                    >
                        {intl.formatMessage({ id: 'homePage.footerSection.privacyPolicy' })}
                    </a>
                </div>
            </div>
        </>
    )
}

export default Layout
