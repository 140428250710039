import React from 'react';
import './NewsSection.scss';

const NewSection = ({ intl }) => {
    return null;

    return (
        <section className="section-news">
            <div className="content-wrapper content-wrapper__section-news">
                <h2 className="title">{intl.formatMessage({ id: 'homePage.newsSection.title' })}</h2>
                <div className="cards">
                    <div className="card">
                        <p>{intl.formatMessage({ id: 'homePage.newsSection.cards.card1' })}</p>
                    </div>
                    <div className="card">
                        <p>{intl.formatMessage({ id: 'homePage.newsSection.cards.card2' })}</p>
                    </div>
                    <div className="card">
                        <p>{intl.formatMessage({ id: 'homePage.newsSection.cards.card3' })}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewSection;
