import React from 'react';
import './Navigation.scss';
import NavMobile from './Navigation/NavMobile';
import NavDesktop from './Navigation/NavDesktop';

const Navigation = ({ isMobile, intl }) => (
    <nav>
        {(!isMobile && isMobile !== null) && <NavDesktop intl={intl} />}
        {(isMobile) && <NavMobile intl={intl} />}
    </nav>
)

export default Navigation
