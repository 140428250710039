export const offerList = {
    pl: [
        {
            offerName: 'Przyszły przedsiębiorca',
            offers: [
                'Doradztwo w zakresie prowadzenia działalności gospodarczej',
                'Pomoc w założeniu firmy',
            ],
            description: 'Masz pytania dotyczące wyboru formy prawnej swojej działalności, rodzaju ewidencji księgowej, formy opodatkowania? Czy wiesz, jakie masz obowiązki wobec urzędu skarbowego i ZUS? Odpowiemy na te i inne pytania.'
        },
        {
            offerName: 'Samozatrudniony',
            offers: [
                'Doradztwo w zakresie prowadzenia działalności gospodarczej',
                'Ryczałt ewidencjonowany',
                'Podatkowa księga przychodów i rozchodów',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Wyprowadzenie zaległości oraz korekty błędów'
            ],
            description: 'Czy wiesz, jakie formy opodatkowania możesz wybrać? Od jakiej podstawy musisz zapłacić ZUS? Odpowiemy na te i inne pytania.'
        },
        {
            offerName: 'Jednoosobowa działalność gospodarcza',
            offers: [
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Ryczałt ewidencjonowany',
                'Podatkowa księga przychodów i rozchodów',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Pisanie odwołań od decyzji ZUS',
                'Wyprowadzanie zaległości oraz korekty błędów',
                'Udział w procesie przekształcenia / zmiany formy prawnej podmiotu'
            ],
            description: 'Gwarantujemy Ci indywidualne podejście, dostosowanie oferty do Twoich potrzeb i preferencji. Chcemy się rozwijać razem z Tobą.'
        },
        {
            offerName: 'Spółka osobowa',
            offers: [
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Podatkowa księga przychodów i rozchodów',
                'Księgi rachunkowe (pełna księgowość)',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzenie sprawozdań finansowych',
                'Opracowanie polityki rachunkowości i innych regulaminów wewnętrznych',
                'Sprawozdawczość statystyczna',
                'Rozliczenia z PFRON',
                'Sporządzenie zestawień i analiz',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Rozliczanie kosztów na projekty',
                'Pisanie odwołań od decyzji ZUS',
                'Wyprowadzanie zaległości oraz korekty błędów',
                'Udział w procesie przekształcenia / zmiany formy prawnej podmiotu'
            ],
            description: 'Gwarantujemy Ci indywidualne podejście, dostosowanie oferty do Twoich potrzeb i preferencji. Chcemy się rozwijać razem z Tobą.'
        },
        {
            offerName: 'Spółka kapitałowa',
            offers: [
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Księgi rachunkowe (pełna księgowość)',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzenie sprawozdań finansowych',
                'Opracowanie polityki rachunkowości i innych regulaminów wewnętrznych',
                'Sprawozdawczość statystyczna',
                'Rozliczenia z PFRON',
                'Sporządzenie zestawień i analiz',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Rozliczanie kosztów na projekty',
                'Pisanie odwołań od decyzji ZUS',
                'Wyprowadzanie zaległości oraz korekty błędów',
                'Udział w procesie przekształcenia / zmiany formy prawnej podmiotu'
            ],
            description: 'Księgowość to nie tylko ustawowy obowiązek. To narzędzie do zarządzania. Zapewnimy Ci maksimum informacji, abyś mógł skutecznie zarządzać swoją firmą.'
        },
        {
            offerName: 'Spółdzielnia mieszkaniowa',
            offers: [
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Księgi rachunkowe (pełna księgowość)',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzenie sprawozdań finansowych',
                'Opracowanie polityki rachunkowości i innych regulaminów wewnętrznych',
                'Sprawozdawczość statystyczna',
                'Rozliczenia z PFRON',
                'Sporządzenie zestawień i analiz',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Rozliczenia z mieszkańcami z tytułu opłat eksploatacyjnych, czynszów i mediów w spółdzielni i wspólnocie mieszkaniowe',
                'Rozliczanie kosztów na nieruchomości w spółdzielni i wspólnocie mieszkaniowej',
                'Wyprowadzanie zaległości oraz korekty błędów',
            ],
            description: 'Chcesz obniżyć koszty działalności? A może chcesz odciążyć pracowników księgowości z niektórych czynności? Dopasujemy swoją ofertę do Twoich potrzeb.'
        },
        {
            offerName: 'Wspólnota mieszkaniowa',
            offers: [
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Księgi rachunkowe (pełna księgowość)',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzenie sprawozdań finansowych',
                'Opracowanie polityki rachunkowości i innych regulaminów wewnętrznych',
                'Sprawozdawczość statystyczna',
                'Rozliczenia z PFRON',
                'Sporządzenie zestawień i analiz',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Rozliczenia z mieszkańcami z tytułu opłat eksploatacyjnych, czynszów i mediów w spółdzielni i wspólnocie mieszkaniowe',
                'Ewidencja przychodów i kosztów nieruchomości wspólnej we wspólnocie mieszkaniowej',
                'Wyprowadzanie zaległości oraz korekty błędów',
            ],
            description: 'Chcesz obniżyć koszty działalności? A może chcesz odciążyć pracowników księgowości z niektórych czynności? Dopasujemy swoją ofertę do Twoich potrzeb.'
        },
        {
            offerName: 'Fundacja',
            offers: [
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Księgi rachunkowe (pełna księgowość)',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzenie sprawozdań finansowych',
                'Opracowanie polityki rachunkowości i innych regulaminów wewnętrznych',
                'Sprawozdawczość statystyczna',
                'Rozliczenia z PFRON',
                'Sporządzenie zestawień i analiz',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Rozliczanie kosztów na projekty',
                'Wyprowadzanie zaległości oraz korekty błędów',
            ],
            description: 'Starasz się o dotacje? Masz wątpliwości co do kwalifikowalności wydatków? Pomożemy Ci w procedurach rozliczeniowych'
        },
        {
            offerName: 'Pełna oferta',
            offers: [
                'Doradztwo w zakresie prowadzenia działalności gospodarczej',
                'Pomoc w założeniu firmy',
                'Konsultacje biznesowe oraz z zakresu prawa pracy i ubezpieczeń społecznych',
                'Ryczałt ewidencjonowany',
                'Podatkowa księga przychodów i rozchodów',
                'Księgi rachunkowe (pełna księgowość)',
                'Kadry i płace',
                'Rozliczenia z urzędem skarbowym i ZUS',
                'Uczestnictwo w kontroli urzędu skarbowego i ZUS',
                'Sporządzanie sprawozdań finansowych',
                'Opracowanie polityki rachunkowości i innych regulaminów wewnętrznych',
                'Sprawozdawczość statystyczna',
                'Rozliczenia z PFRON',
                'Sporządzanie zestawień i analiz',
                'Sporządzanie przelewów w systemie bankowości elektronicznej Klienta',
                'Rozliczenia z mieszkańcami z tytułu opłat eksploatacyjnych, czynszów i mediów w spółdzielni i wspólnocie mieszkaniowej',
                'Rozliczanie kosztów na projekty',
                'Rozliczanie kosztów na nieruchomości w spółdzielni mieszkaniowej',
                'Ewidencja przychodów i kosztów nieruchomości wspólnej we wspólnocie mieszkaniowej',
                'Pisanie odwołań od decyzji ZUS',
                'Wyprowadzanie zaległości oraz korekty błędów',
                'Udział w procesie przekształcenia / zmiany formy prawnej podmiotu'
            ]
        },
    ],
    en: [
        {
            offerName: 'Future entrepreneur',
            offers: [
                'Business consultancy',
                'Help in starting a business',
            ],
            description: 'Do you have questions about choosing the legal form of your business, type of accounting records, form of taxation? Do you know what your obligations are towards the tax office and the Social Insurance Institution? We will answer these and other questions.'
        },
        {
            offerName: 'Self-employed',
            offers: [
                'Business consultancy',
                'Checked lump sum',
                'Tax book of revenues and expenses',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Removal of arrears and correction of errors'
            ],
            description: 'Do you know what forms of taxation you can choose? From what basis do you have to pay Social Insurance Institution? We will answer these and other questions.'
        },
        {
            offerName: 'A sole proprietorship',
            offers: [
                'Business consultations and consultations in the field of labor law and social security',
                'Checked lump sum',
                'Tax book of revenues and expenses',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Writing appeals against Social Insurance Institution decisions',
                'Removal of arrears and correction of errors',
                'Participation in the process of transformation / change of the legal form of the entity'
            ],
            description: 'We guarantee you an individual approach, tailoring the offer to your needs and preferences. We want to develop with you.'
        },
        {
            offerName: 'Partnership',
            offers: [
                'Business consultations and consultations in the field of labor law and social security',
                'Tax book of revenues and expenses',
                'Books of accounts (full accounting)',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of financial statements',
                'Development of accounting policy and other internal regulations',
                'Statistical reporting',
                'Settlements with PFRON',
                'Preparation of statements and analyzes',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Settlement of costs for projects',
                'Writing appeals against Social Insurance Institution decisions',
                'Removal of arrears and correction of errors',
                'Participation in the process of transformation / change of the legal form of the entity'
            ],
            description: 'We guarantee you an individual approach, tailoring the offer to your needs and preferences. We want to develop with you.'
        },
        {
            offerName: 'Capital company',
            offers: [
                'Business consultations and consultations in the field of labor law and social security',
                'Books of accounts (full accounting)',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of financial statements',
                'Development of accounting policy and other internal regulations',
                'Statistical reporting',
                'Settlements with PFRON',
                'Preparation of statements and analyzes',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Settlement of costs for projects',
                'Writing appeals against Social Insurance Institution decisions',
                'Removal of arrears and correction of errors',
                'Participation in the process of transformation / change of the legal form of the entity'
            ],
            description: 'Accounting is not only a statutory obligation. It\'s a management tool. We will provide you with maximum information so that you can effectively manage your business.'
        },
        {
            offerName: 'Housing Association',
            offers: [
                'Business consultations and consultations in the field of labor law and social security',
                'Books of accounts (full accounting)',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of financial statements',
                'Development of accounting policy and other internal regulations',
                'Statistical reporting',
                'Settlements with PFRON',
                'Preparation of statements and analyzes',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Settlements with residents for service charges, rents and utilities in the cooperative and housing community',
                'Settlement of costs on real estate in a housing cooperative',
                'Removal of arrears and correction of errors',
            ],
            description: 'Do you want to reduce your operating costs? Or maybe you want to relieve the accounting staff of some activities? We will adjust our offer to your needs.'
        },
        {
            offerName: 'Housing Association',
            offers: [
                'Business consultations and consultations in the field of labor law and social security',
                'Books of accounts (full accounting)',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of financial statements',
                'Development of accounting policy and other internal regulations',
                'Statistical reporting',
                'Settlements with PFRON',
                'Preparation of statements and analyzes',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Settlements with residents for service charges, rents and utilities in the cooperative and housing community',
                'Records of revenues and costs of common real estate in a housing community',
                'Removal of arrears and correction of errors',
            ],
            description: 'Do you want to reduce your operating costs? Or maybe you want to relieve the accounting staff of some activities? We will adjust our offer to your needs.'
        },
        {
            offerName: 'Foundation',
            offers: [
                'Business consultations and consultations in the field of labor law and social security',
                'Books of accounts (full accounting)',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of financial statements',
                'Development of accounting policy and other internal regulations',
                'Statistical reporting',
                'Settlements with PFRON',
                'Preparation of statements and analyzes',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Settlement of costs for projects',
                'Removal of arrears and correction of errors',
            ],
            description: 'Are you applying for subsidies? Do you have doubts as to the eligibility of expenses? We will help you with billing procedures.'
        },
        {
            offerName: 'Full offer',
            offers: [
                'Business consultancy',
                'Help in starting a business',
                'Business consultations and consultations in the field of labor law and social security',
                'Checked lump sum',
                'Tax book of revenues and expenses',
                'Books of accounts (full accounting)',
                'HR and payroll',
                'Settlements with the tax office and the Social Insurance Institution',
                'Participation in the control of the tax office and the Social Insurance Institution',
                'Preparation of financial statements',
                'Development of accounting policy and other internal regulations',
                'Statistical reporting',
                'Settlements with PFRON',
                'Preparation of statements and analyzes',
                'Preparation of transfers in the Customer\'s electronic banking system',
                'Settlements with residents for service charges, rents and utilities in the cooperative and housing community',
                'Settlement of costs for projects',
                'Settlement of costs on real estate in a housing cooperative',
                'Records of revenues and costs of common real estate in a housing community',
                'Writing appeals against Social Insurance Institution decisions',
                'Removal of arrears and correction of errors',
                'Participation in the process of transformation / change of the legal form of the entity'
            ]
        },
    ]
}
