import React from 'react';
import { Link } from 'react-scroll'
import './HeaderSection.scss';
import HeroImage from './../../images/homepage-hero-image.svg'
import { handlerTracking } from '../../helpers/ga';

const HeaderSection = ({ intl }) => (
    <section className="header-section">
        <div className="content-wrapper content-wrapper__header">
            <div className="row">
                <div className="col-6 col-left">
                    <h1>{intl.formatMessage({ id: 'homePage.heroSection.title' })}</h1>
                    <p>{intl.formatMessage({ id: 'homePage.heroSection.description' })}</p>
                    <div className="hero-buttons">
                        <Link to="benefit-section" offset={-50} smooth={true} duration={800} type="button"
                            className="hero-buttons__btn"
                            onClick={() => handlerTracking('Sekcja Główna', 'Kliknięcie', 'Dowiedz się więcej')}>
                            {intl.formatMessage({ id: 'homePage.heroSection.moreButton' })}
                        </Link>
                        <Link to="contact-section" offset={-50} smooth={true} duration={800} type="button"
                            className="hero-buttons__btn"
                            onClick={() => handlerTracking('Sekcja Główna', 'Kliknięcie', 'Zobacz cenę')}>
                            {intl.formatMessage({ id: 'homePage.heroSection.seePriceButton' })}
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-right">
                    <div className="image-container">
                        <img src={HeroImage} alt="hero-background" />
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default HeaderSection;
