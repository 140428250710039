import React, { useEffect, useState } from 'react';
import './OfferSection.scss';
import { offerList } from '../../helpers/offer-list';
import { handlerTracking } from '../../helpers/ga';

const OfferSection = ({ intl }) => {
    const [dataShow, setDataShow] = useState([]);
    const [height, setHeight] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [randomNumber, setRandomNumber] = useState(null);
    const [endRandomNumber, setEndRandomNumber] = useState(false);
    const [dataDescription, setDataDescription] = useState(null);

    const handlerShowData = (index) => {
        handlerTracking('Sekcja Oferta', 'Kliknięcie', `Otwórz ofertę - ${offerList['pl'][index].offerName}`)

        if (window.innerWidth > 580) {
            setHeight(offerList[intl.locale][index].offers.length * 30)
        } else {
            setHeight('100%')
        }
        setDataShow(offerList[intl.locale][index].offers)
        setDataDescription(offerList[intl.locale][index].description)
        setCurrentIndex(index);
        setEndRandomNumber(true);
        setRandomNumber(null);
    }

    useEffect(() => {
        const getRandomInt = (min, max) => {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }


        const randomNumberSetter = setInterval(() => {
            if (!endRandomNumber) {
                setRandomNumber(getRandomInt(0, 6))
            }
        }, 3000)

        return () => {
            clearInterval(randomNumberSetter)
        }
    }, [endRandomNumber])

    return (
        <section className="offer-section" id="offer-section">
            <div className="content-wrapper content-wrapper__section-offer">
                <h2 className="title">{intl.formatMessage({ id: 'homePage.offerSection.title' })}</h2>
                <p className="description">{intl.formatMessage({ id: 'homePage.offerSection.description' })}</p>
                <ul className={`offer-list lang-${intl.locale}`}>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '0' ? 'active' : ''} ${randomNumber === 0 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('0')}>{offerList[intl.locale][0].offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '1' ? 'active' : ''} ${randomNumber === 1 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('1')}>{offerList[intl.locale][1].offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '2' ? 'active' : ''} ${randomNumber === 2 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('2')}>{offerList[intl.locale][2].offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '3' ? 'active' : ''} ${randomNumber === 3 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('3')}>{offerList[intl.locale][3].offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '4' ? 'active' : ''} ${randomNumber === 4 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('4')}>{offerList[intl.locale][4].offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '7' ? 'active' : ''} ${randomNumber === 5 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('7')}>{offerList[intl.locale][7].offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button type="button"
                            className={`${currentIndex === '8' ? 'active' : ''} ${randomNumber === 6 ? 'highlight' : ''}`}
                            onClick={() => handlerShowData('8', 40)}>{offerList[intl.locale][8].offerName}
                        </button>
                    </li>
                </ul>
                {dataDescription && <p className="extra-description">{dataDescription}</p>}
                <ul className="offer-list-details" style={{ height }}>
                    {dataShow.length > 0 && (
                        dataShow.map((item, index) => (
                            <li key={item} className={`offer-list-details__detail delay-${100 * index}`}>
                                {item}
                            </li>
                        ))
                    )}
                </ul>
            </div>
        </section>
    )
}

export default OfferSection;
