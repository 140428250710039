import React from 'react';
import './PriceSection.scss';
import { handlerTracking } from '../../helpers/ga';
import CertyfikatImg from '../../static/dokumenty/certyfikat-ksiegowy-compressed.jpg';
import SygnatariuszImg from '../../static/dokumenty/sygnatariusz-compressed.jpg';

const PriceSection = ({ intl }) => {
    return (
        <section className="price-section" id="price-section">
            <div className="content-wrapper content-wrapper__section-price">
                <div className="content-wrapper__section-price__header">
                    <h2 className="title">{intl.formatMessage({ id: 'homePage.priceSection.title' })}</h2>
                    <a
                        className="trustful-company"
                        target="_blank"
                        href="https://wizytowka.rzetelnafirma.pl/1LHU5YTB"
                        rel="follow"
                    >
                        <img
                            src="https://aktywnybaner.rzetelnafirma.pl/ActiveBanner/GetActiveBannerImage/13/1LHU5YTB"
                        />
                    </a>
                </div>
                <div className="content-wrapper__section-price__body">
                    <div className="content-wrapper__section-price__body__left">
                        <div className="content-wrapper__section-price__body__left__img">
                            <img
                                onClick={() => window.open(CertyfikatImg, '_blank')}
                                src={CertyfikatImg}
                                alt="certyfikat"
                            />
                        </div>
                    </div>

                    <div className="content-wrapper__section-price__body__right">
                        <div className="content-wrapper__section-price__body__right__img">
                            <img
                                onClick={() => window.open(SygnatariuszImg, '_blank')}
                                src={SygnatariuszImg}
                                alt="sygnatariusz"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PriceSection;
