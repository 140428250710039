import React from 'react';
import './Footer.scss';
import { handlerTracking } from '../../helpers/ga';

const Footer = ({ intl }) => {

    return (
        <footer>
            <div className="content-wrapper content-wrapper__footer">
                <div className="col-400 col-left">
                    <h3>{intl.formatMessage({ id: 'homePage.footerSection.companyData' })}</h3>
                    <p>eRachunkowa Sp. z o.o.</p>
                    <p>Certyfikat Ministra Finansów nr 67907</p>
                    <br />
                    <p>ul. Afrykańska 12D lok. 11</p>
                    <p>03-966 Warszawa <a
                        href="https://www.google.com/maps/place/Afryka%C5%84ska+12D,+Warszawa,+Poland/@52.226405,21.0694952,17z/data=!3m1!4b1!4m2!3m1!1s0x471ecda077b8c0ed:0x275680775b97bf37"
                        target="_blank" rel="noreferrer"
                        onClick={() => handlerTracking('Sekcja Stopka', 'Kliknięcie', 'zobacz na mapie')}>({intl.formatMessage({ id: 'homePage.footerSection.seeOnMap' })})</a>
                    </p>
                    <br />
                    <p>KRS: 0000628984</p>
                    <p>NIP: 113-291-46-60</p>
                    <p>REGON: 365019927</p>
                    <br />
                    <p>Kapitał zakładowy: 5 000 zł</p>
                </div>
                <div className="col-400 col-mid">
                    <h3>{intl.formatMessage({ id: 'homePage.footerSection.contactData' })}</h3>
                    <a href="mailto:sekretariat@erachunkowa.pl" target="_blank" rel="noreferrer"
                        onClick={() => handlerTracking('Sekcja Stopka', 'Kliknięcie', 'adres email')}>sekretariat@erachunkowa.pl</a>
                    <a href="tel:+48530434429"
                        onClick={() => handlerTracking('Sekcja Stopka', 'Kliknięcie', 'numer telefonu')}>tel. 530 434
                        429</a>
                </div>
                <div className="col-400 col-right">
                    <h3>{intl.formatMessage({ id: 'homePage.footerSection.coreBusiness' })}</h3>
                    <div className="additional-container">
                        <p>
                            eRachunkowa Sp. z o.o.
                            {' '}
                            {intl.formatMessage({ id: 'homePage.footerSection.coreBusinessDescription' })}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
