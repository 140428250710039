import React, { useState } from 'react';
import { Link } from 'react-scroll'
import Logo from '../../images/eRachunkowa_logo_white.svg';
import { changeLocale } from 'gatsby-plugin-intl';
import { handlerTracking } from '../../helpers/ga';

const NavDesktop = ({ intl }) => {
    const [language] = useState(intl.locale === 'fr' ? 'en' : intl.locale);
    const [selectLanguage, setSelectLanguage] = useState(false);

    const handlerSelectLanguage = () => setSelectLanguage(!selectLanguage);
    const handlerChangeLanguage = (lang) => {
        handlerTracking('Nawigacja Komputerowa', 'Kliknięcie', `Zmień język na ${lang}`)
        changeLocale(lang)
    };

    return (
        <div className="navigation-desktop-wrapper stroke">
            <img className="logo" src={Logo} alt="main logo" />
            <div className="row">
                <ul className="nav-list">
                    <li className="nav-list__item">
                        <Link to="benefit-section" offset={-50} smooth={true} duration={800} type="button"
                            onClick={() => handlerTracking('Nawigacja Komputerowa', 'Kliknięcie', 'Przejdź do Korzyści')}>
                            {intl.formatMessage({ id: 'navigation.benefit' })}
                        </Link>
                    </li>
                    <li className="nav-list__item">
                        <Link to="offer-section" offset={-50} smooth={true} duration={800} type="button"
                            onClick={() => handlerTracking('Nawigacja Komputerowa', 'Kliknięcie', 'Przejdź do Oferta')}>
                            {intl.formatMessage({ id: 'navigation.offer' })}
                        </Link>
                    </li>
                    <li className="nav-list__item">
                        <Link to="contact-section" offset={-50} smooth={true} duration={800} type="button"
                            onClick={() => handlerTracking('Nawigacja Komputerowa', 'Kliknięcie', 'Przejdź do Cena')}>
                            {intl.formatMessage({ id: 'navigation.price' })}
                        </Link>
                    </li>
                    <li className="nav-list__item">
                        <Link className="contact-btn" to="contact-section" offset={-50} smooth={true} duration={800}
                            type="button"
                            onClick={() => handlerTracking('Nawigacja Komputerowa', 'Kliknięcie', 'Przejdź do Kontakt')}>
                            {intl.formatMessage({ id: 'navigation.contact' })}
                        </Link>
                    </li>
                </ul>
                <div className="language-wrapper">
                    <button
                        type="button" className="erachunkowa-navigation__list-item--language-button"
                        onClick={handlerSelectLanguage}
                    >
                        {language.toUpperCase()}
                    </button>
                    {selectLanguage && (
                        <ul className="erachunkowa-navigation__select-language">
                            <li className="erachunkowa-navigation__select-language--item">
                                <button
                                    type="button"
                                    className={language === 'pl' ? 'active' : ''}
                                    onClick={() => handlerChangeLanguage('pl')}
                                >
                                    Polski
                                </button>
                            </li>
                            <li className="erachunkowa-navigation__select-language--item">
                                <button
                                    type="button"
                                    className={language === 'en' ? 'active' : ''}
                                    onClick={() => handlerChangeLanguage('en')}
                                >
                                    English
                                </button>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NavDesktop;
